<template>
    <div class="row mx-0 align-items-start d-flex mt-5">
        <div class="col-md-3 col-sm-12"><b-img alt="Logo" :src="require('../../assets/LogoDamata.png')" class="col-12"></b-img></div>
        <div class="col-md-5 col-sm-12">
            <p class="titulo-footer">Transportes Damata</p>
            <p>
                <b-link class="texto-footer">Solicite Cotação</b-link>
            </p>
            <p>
                <b-link class="texto-footer">Transporte de Carga Rodoviário</b-link>
            </p>
            <p>
                <b-link class="texto-footer">Transporte de Carga Fracionado Convecional</b-link>
            </p>
            <p>
                <b-link class="texto-footer">Transporte de carga Fracionado Expresso</b-link>
            </p>
            <p>
                <b-link class="texto-footer">Transporte de carga Exclusivo</b-link>
            </p>
        </div>
        <div class="col-md-4 col-sm-12">
            <p class="titulo-footer">Contatos</p>
            <p>
                <b-link class="texto-footer"><font-awesome-icon icon="fa-brands fa-whatsapp" />&nbsp; (11) 93742-3698</b-link>
            </p>
            <p>
                <b-link class="texto-footer"><font-awesome-icon icon="fa-solid fa-envelope" />&nbsp; logistica@damatatransportes.com</b-link>
            </p>
            <p>
                <b-link class="texto-footer"><font-awesome-icon icon="fa-solid fa-location-dot" />&nbsp; Guarulhos/São Paulo</b-link>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterHomePage",
}
</script>

<style scoped>
.titulo-footer {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    /* identical to box height */

    display: flex;
    align-items: flex-end;
    text-align: center;

    color: #00A538;
}

.texto-footer {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 1.2rem;
    /* line-height: 1.2rem; */
    display: flex;
    align-items: center;

    color: #00A538;
}

.texto-footer:hover {
    color: #004216;
}
</style>