<template>
  <div class="home-page" @scroll="handleScroll">
    <Teleport to="head">
      <title>Damata Transportes - Home</title>
      <meta name="description"
        content="Transportadora Damata - Soluções de transporte confiáveis e eficientes para sua empresa.
           Nossos serviços de transporte de carga e logística são adaptados às necessidades do seu negócio.
            Contamos com uma equipe de profissionais altamente qualificados e uma frota moderna e segura.
             Solicite já um orçamento e confie na Transportadora Damata para levar sua empresa a novos patamares.: Com baixo custo">

    </Teleport>
    <HeaderHomePage />
    <ContentHomePage :scrollData="this.scrollData" />
    <FooterHomePage />
  </div>
</template>

<script lang="js">
import HeaderHomePage from './HeaderHomePage.vue'
import ContentHomePage from './ContentHomePage.vue'
import FooterHomePage from './FooterHomePage.vue'

export default {
  name: "HomePage",
  components: {
    HeaderHomePage,
    ContentHomePage,
    FooterHomePage
  },
  data() {
    return {
      bottom: true,
      scrollData: { scrollHeight: 0, scrollTop: 0, clientHeight: 0 },

    }
  },

  methods: {
    handleScroll(event) {
      const scrollHeight = event.target.scrollHeight
      const scrollTop = event.target.scrollTop
      const clientHeight = event.target.clientHeight
      this.scrollData = { scrollHeight, scrollTop, clientHeight };

      // if (scrollTop + clientHeight >= scrollHeight) {
      //   console.log('Yay!')
      //   this.bottom = true
      // } else {
      //   this.bottom = false
      // }
      /*
      900 979 - quem somos
      1900 979 - missao
      2300 979 - visao
      2700 979 - valores
      2900 979 - cards
      */
    },
  }

}
</script>
<style>
.home-page {
  overflow-y: scroll;
  height: 100vh;
}
</style>