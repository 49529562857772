<template>
    <div class="row mx-0 mt-5">
        <p class="titulo texto-verde" id="faleConosco">FALE CONOSCO</p>
        <div class="row mt-5 p-0 mx-0">
            <div class="col-md col-lg-6">
                <p class="titulo texto-verde">SOLICITE ORÇAMENTO</p>
                <FormularioEmail :tituloHidden="true" :corpoClass="'col-lg-12'"
                    :botaoStyle="'border-bottom: .2rem #00A538 solid;'" :corpoStyle="`
                                position: relative;
                                top: 0;
                                left: 0;
                                margin-top: 0;
            margin-bottom: 0;
                                                    `" />
            </div>
            <div class="col-md col-lg-6 corpo-contato">
                <p class="titulo texto-verde">CONTATOS</p>
                <div class="row justify-content-lg-center justify-content-sm-center">
                    <div class="col-3 icone p-0">
                        <font-awesome-icon icon="fa-brands fa-whatsapp" />
                    </div>
                    <div class="col-8 col-md-7 col-lg-5 d-flex align-items-center justify-content-start ">
                        <p class="texto-card"><span class="texto-verde titulo-card">WhatsApp</span>
                            <br>
                            (11) 93742-3698
                        </p>
                    </div>
                </div>
                <div class="row justify-content-lg-center justify-content-sm-center">
                    <div class="col-3 icone p-0">
                        <font-awesome-icon icon="fa-solid fa-envelope" />
                    </div>
                    <div class="col-8 col-md-7 col-lg-5 d-flex align-items-center justify-content-start ">
                        <p class="texto-card"><span class="texto-verde titulo-card">E-mail</span>
                            <br>
                            logistica@damatatransportes.com
                        </p>
                    </div>
                </div>
                <div class="row justify-content-lg-center justify-content-sm-center">
                    <div class="col-3   p-0 icone">
                        <font-awesome-icon icon="fa-solid fa-location-dot" />
                    </div>
                    <div class="col-8 col-md-7 col-lg-5  d-flex align-items-center justify-content-start ">
                        <p class="texto-card"><span class="texto-verde titulo-card">Localização</span>
                            <br>
                            Guarulhos/São Paulo
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormularioEmail from "../Utils/FormularioEmail.vue"
export default {
    name: "FaleConoscoHomePage",
    components: { FormularioEmail }
}
</script>

<style>
.texto-verde {
    color: #00A538;
}

.texto-card {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 75px;
    align-items: left;
    text-align: left;
    width: 100%;
}

.titulo-card {
    font-size: 2rem;
}

.icone {
    right: 0;
    font-size: 8rem;
}

.corpo-contato {
    background: rgba(0, 165, 56, 0.1);
}

@media(max-width: 1200px) {
    .titulo-card {
        font-size: 1rem;
    }

    .icone {
        right: 0;
        font-size: 5rem;
    }
}
</style>