<template>
  <div>

    <div>
      <figure class="teste">
        <img src="@/assets/caminha1_Invertido.jpg" class="caminhaoCarrousel h-100" alt="Caminhão" />
        <div :class="'row-0 align-items-center ' + fadeInLeft">
          <div class=" col offset-1 pt-5">
            <h2 class="effectPhrase">TRANSPORTE
              <br>
              RODOVIÁRIO
              <br>
              INTERESTADUAL
              <br>
              DE CARGAS
            </h2>
          </div>

        </div>
        <div :class="'row-0 align-items-center ' + fadeInLeft">
          <div class="col offset-1 pt-5">
            <p class="effectPhrase downLetters ">TRANSPORTANDO SUA CARGA COM
              <br> RAPIDEZ E
              SEGURANÇA
            </p>
          </div>
        </div>
        <div :class="'row-0 align-items-center ' + fadeInLeft">
          <div class="col offset-md-1 pt-5">
            <b-link target="_blank" href="https://wa.me/5511937423698?text=Olá, Gostaria de solicitar um orçamento." style="text-decoration: none;">
              <b-button class="p-relative batata mr-auto">
                <font-awesome-icon icon="fa-brands fa-whatsapp" />
                Peça já seu orçamento
              </b-button>
            </b-link>

          </div>

        </div>


      </figure>
    </div>

    <NossosServicosHomePage :scrollData="this.scrollData" />
    <!-- <FormularioEmail /> -->
    <QuemSomosHomePage :scrollData="this.scrollData" />
    <NossasCargasHomePage :scrollData="this.scrollData" />
    <FaleConoscoHomePage />
    <div class="btn-flutuante-box">
      <b-link target="_blank" href="https://wa.me/5511937423698?text=Olá, Gostaria de solicitar um orçamento." >
        <b-button class="btn-wpp-flutuante"><font-awesome-icon class="wpp-icon-flutuante" icon="fa-brands fa-whatsapp" />
        </b-button>
      </b-link>
    </div>

  </div>
</template>

<script lang="js">
import NossosServicosHomePage from './NossosServicosHomePage.vue';
import QuemSomosHomePage from './QuemSomosHomePage.vue';
import NossasCargasHomePage from './NossasCargasHomePage.vue';
import FaleConoscoHomePage from './FaleConoscoHomePage.vue';

// import FormularioEmail from '../Utils/FormularioEmail.vue'

export default {
  name: "ContentHomePage",
  props: ['scrollData'],
  data() {
    return {
      animator: false,
    }
  },
  components: { NossosServicosHomePage, QuemSomosHomePage, NossasCargasHomePage, FaleConoscoHomePage },
  methods: {
    classAnimator(animacao) {
      if (
        (this.scrollData.scrollTop + this.scrollData.clientHeight >= 0 &&
          this.scrollData.scrollTop + this.scrollData.clientHeight <= 1800
        ) && !this.animator) {

        this.animator = true;
        return animacao;
        // {scrollHeight,scrollTop,clientHeight};aaaa
      }
      else if (this.animator == true) {
        return animacao
      } else {
        return '';
      }
    }
  },
  computed: {
    fadeInLeft() {
      return this.classAnimator('animate__animated  animate__fadeInLeft');
    }
  }
}
</script>

<style>
.teste {
  position: relative;
  height: 735px;
  background: linear-gradient(89.89deg, #009A06 51.15%, rgba(116, 192, 119, 0) 99.89%);
}

.batata {
  z-index: 3;
  /* margin-left: 5%; */
  /* margin-top: 250px; */
  /* width: 60%; */

  text-align: center;
  background-color: #FFFFFF !important;
  color: #009A06 !important;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 50px !important;
  line-height: 77px;
  display: flex;
  align-items: center;
  text-align: center;

}

.batata:hover {
  background-color: #b8b8b8 !important;
}

.caminhaoCarrousel {
  /* width: auto;
  height: auto; */
  /* background-size: 100%; */
  /* position: absolute;
  opacity: 0.5;
  display: block;
  width: 100%;
  height: 735px; */
  position: absolute;
  opacity: 0.5;
  display: block;
  width: 100%;
  z-index: 0;
  height: 735px;
  object-fit: cover;
}

.effectPhrase {
  position: relative;
  /* top: 25%;
  left: 5%; */
  z-index: 3;
  margin: 0 0;
  width: 100%;
  vertical-align: center;
  text-align: left;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 77px;
  letter-spacing: -0.04em;

  color: #FFFFFF;
}

.titulo {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 5rem;
  line-height: 112px;
  align-items: center;
  text-align: center;
  width: 100%;
}

.texto-descritivo {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 38px;
  text-align: left;
  align-items: center;
  margin: 3rem;
}

.texto-subtitulo {
  font-weight: 700;
}

.btn-wpp-flutuante {
  cursor: pointer;
  width: auto;
  height: auto;
  border-radius: 30px;
  background-color: #1BD741 !important;
  border: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .4);
  font-size: 24px;
  color: white;

  border: none !important;


  -webkit-transition: .2s ease-out;
  -moz-transition: .2s ease-out;
  transition: .2s ease-out;
  z-index: 999;

}

.btn-wpp-flutuante:hover {
  background-color: #0b6d1f !important;

}

.wpp-icon-flutuante {
  font-size: 3rem;
}

.btn-flutuante-box {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 999;

}

@media(max-width: 600px) {
  .effectPhrase {
    font-size: 40px;
    line-height: 50px;
  }

  .batata {
    margin-left: 0;
    font-size: 35px !important;
    width: 100%;
    text-align: center;
  }

}

@media(max-width: 1200px) {
  .batata {
    margin-left: 0;
    /* font-size: 30px !important; */
    /* width: 100%; */
  }

  .titulo {
    font-size: 3rem;
  }

  .texto-descritivo {
    font-size: 1.3rem;
    line-height: 25px;
  }
}

.downLetters {
  margin-top: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
}
</style>