<template>
    <div>
        <!-- <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"> -->
        <div class="slogan">
            <p>“Pontualidade, responsabilidade e honestidade, nossa carga mais preciosa”</p>
        </div>
    <b-navbar toggleable="lg" type="dark" variant="none">
    <b-navbar-brand  class="mr-auto"><img src="@/assets/LogoDamata.png" alt="Logo" width="200" height="100"/></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav class="justify-content-end">
      <b-navbar-nav class="mx-auto">
        <b-nav-item href="#" ><p class="link">Inicio</p></b-nav-item>
        <b-nav-item href="#nossosServicos" ><p class="link">Serviços</p></b-nav-item>
        <b-nav-item href="#quemSomos" ><p class="link">Quem Somos</p></b-nav-item>
        <b-nav-item href="#nossasCargas" ><p class="link">Nossas Cargas</p> </b-nav-item>        
        <b-nav-item href="#faleConosco" ><p class="link">Contato</p></b-nav-item>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto d-flex">
        <b-navbar-brand href="#" >
          <img src="@/assets/Brasao-CB.svg" alt="Bombeiros_Logo" width="100"/>
          <img src="@/assets/policia-civil-sao-paulo.png" alt="Policial_Civil_Logo" width="70"/>
        </b-navbar-brand>
      </b-navbar-nav>
      <b-navbar-nav class="mr-auto">
        <b-nav-item target="_blank" href="https://wa.me/5511937423698?text=Olá, Gostaria de solicitar um orçamento." >
            <b-button class="my-2 my-sm-0 botaoWppColor">            
                    <font-awesome-icon icon="fa-brands fa-whatsapp" /> 
                    Peça já seu orçamento
            </b-button>
        </b-nav-item>      
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
    </div>
   
</template>

<script>

export default {
name: "HeaderHomePage"
}
</script>

<style>
.link{
  position:relative;
  display: inline-block;
  cursor: pointer;

    color: #00A538;
    
}
.link::before{
  content: '';
  position:absolute;
  /* left:0; */
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #00A538;
  border-radius: 4px;
  scale: 0 1;
  transform-origin: left;
  transition: scale 0.35s;
}
.link:hover::before{
  scale:1;
}
.link:hover{
  color:#006401;
}
.slogan{
background: #006401;
border-radius: 0px 0px 30px 30px;

/* font-family: 'Inter';
font-style: normal;
font-weight: 200;
font-size: 24px;
line-height: 29px; */
/* identical to box height */

text-align: center;

color: #FFFFFF;
}
.botaoWpp{
    font-size: large;
}
.botaoWppColor{
    text-align: center;

  background-color: #00A538 !important;
}
.botaoWppColor:hover{
  background-color: #02501c !important;
}
</style>