import { createApp } from 'vue'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faBullseye, faEnvelope,faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faGem, faEye } from '@fortawesome/free-regular-svg-icons'
import animate from 'animate.css'

import BootstrapVue3 from 'bootstrap-vue-3'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

library.add(faWhatsapp)
library.add(faBullseye)
library.add(faGem)
library.add(faEye)
library.add(faEnvelope)
library.add(faLocationDot)

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(BootstrapVue3)
app.use(FontAwesomeIcon)
app.use(animate)

app.mount('#app')
