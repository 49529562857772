<template>
    <div class="row mx-0 mt-5">
        <p ref="nossasCargas" :class="'titulo texto-verde ' + fadeInUp" id="nossasCargas">NOSSAS CARGAS</p>
        <div ref="CaminhaCardFinal">
            <b-card-group columns class="mt-5 py-3 card-grupo">
                <b-card ref="caminhaoCard" :class="'cardCorpo row-md ' + fadeInRightBig" img-alt="Caminhão"
                    :img-src="require('../../assets/caminhaoNossosServicos.jpg')" img-height="400px" img-width="200px">
                    <b-card-title class="titulo-card texto-verde">CARGA DIRETA</b-card-title>
                    <b-card-text class="texto-card">
                        Coletamos a carga completa na sua origem e levamos diretamente ao seu cliente, sem baldeações bem
                        como,
                        sem armazenagem, propiciando maior agilidade e menor prazo de entrega.
                    </b-card-text>
                </b-card>

                <b-card :class="'cardCorpo row-md ' + fadeInRight" :img-src="require('../../assets/caminhaoCard.jpeg')"
                    img-alt="Caminhão" img-top>
                    <b-card-title class="titulo-card texto-verde">PROGRAMADA</b-card-title>
                    <b-card-text class="texto-card">
                        Serviços agendados, ideal para necessidades estritas.
                    </b-card-text>
                </b-card>

                <b-card :class="'cardCorpo row-md ' + fadeInRight" :img-src="require('../../assets/caminha1_Invertido.jpg')"
                    img-alt="Caminhão" img-top>
                    <b-card-title class="titulo-card texto-verde">FRACIONADA</b-card-title>
                    <b-card-text class="texto-card">
                        Modelo de operação onde disponibilizamos um ou mais veículos de nossa frota com exclusividade para
                        ser
                        levado para seu cliente.
                    </b-card-text>
                </b-card>
            </b-card-group>
        </div>

    </div>
</template>

<script>
export default {
    name: "NossasCargasHomePage",
    props: ["scrollData"],
    data() {
        return {
            animator: false,
        }
    },
    methods: {
        classAnimator(animacao, elemento) {
            let coordenadas;
            if (elemento) {
                coordenadas = elemento?.getBoundingClientRect();
            }
            if (
                (1500 >= (coordenadas?.bottom || 8000) &&
                    (coordenadas?.bottom || -8000) >= -1500
                ) && !this.animator) {
                this.animator = true;
                return animacao;
                // {scrollHeight,scrollTop,clientHeight};aa
            }
            else if (this.animator == true) {
                return animacao
            } else {
                return '';
            }
        },
        retornaElemento(elemento) {
            return this.$refs[elemento];
        }
    },
    computed: {
        fadeInRight() {
            if (this.scrollData) {
                return this.classAnimator('animate__animated animate__fadeInRight', this.retornaElemento('CaminhaCardFinal'));
            }
            return '';
        },
        fadeInRightBig() {
            if (this.scrollData) {
                return this.classAnimator('animate__animated animate__fadeInRightBig', this.retornaElemento('CaminhaCardFinal'));
            }
            return '';
        },
        fadeInUp() {
            if (this.scrollData) {
                return this.classAnimator('animate__animated animate__fadeInUp', this.retornaElemento('nossasCargas'));
            }
            return '';
        }
    }
}
</script>

<style scoped>
.texto-verde {
    color: #00A538;
}

.titulo-card {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 2.6rem;
    line-height: 75px;
    align-items: center;
    text-align: center;
}

.texto-card {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    display: flex;
    align-items: center;
    text-align: center;
    min-height: 3rem;
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
    color: #5A5A5A;

}

.cardCorpo {
    border-bottom: #00A538 solid 3px !important;
    min-width: 275px;
    filter: drop-shadow(0px 8px 50px rgba(0, 0, 0, 0.25));
    margin-top: 2rem;

}

.card-grupo {
    background: radial-gradient(73.84% 277.47% at 49.3% 48.49%, rgba(217, 217, 217, 0) 57.21%, #00A538 100%);
}

</style>