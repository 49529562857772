<template>
  <HomePage />

</template>

<script>
import HomePage from './components/Home/HomePage.vue'

export default {
  name: 'App',
  components: {
    HomePage
  },
  // metaInfo: {
  //   meta: [
  //     { charset: 'utf-8' },
  //     { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  //     { name: 'description', 
  //     content: `Transportadora Damata - Soluções de transporte confiáveis e eficientes para sua empresa.
  //      Nossos serviços de transporte de carga e logística são adaptados às necessidades do seu negócio.
  //       Contamos com uma equipe de profissionais altamente qualificados e uma frota moderna e segura.
  //        Solicite já um orçamento e confie na Transportadora Damata para levar sua empresa a novos patamares.: Com baixo custo` },
  //   ]
  // },
  // metaInfo() {
  //   return {
  //     title: "Home - Damata Transportadora",
  //     meta: [
  //       { name: 'description', content: 'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.' },
  //       { property: 'og:title', content: "Epiloge - Build your network in your field of interest" },
  //       { property: 'og:site_name', content: 'Epiloge' },
  //       { property: 'og:type', content: 'website' },
  //       { name: 'robots', content: 'index,follow' }
  //     ]
  //   }
  // }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
@import url('https://fonts.googleapis.com/css?family=Inter');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */

}

html {
  font-size: 1rem;
  overflow: hidden;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.4rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.6rem;
  }


}
</style>
