<template>
    <div>
        <figure class="caixaImagem ">
            <img src="@/assets/Caminhao_QuemSomos.jpeg" class="caminhaoCardGrande col-4" alt="Caminhão">
            <div class="row m-0 d-flex h-100 ">
                <div class="col-sm-12 col-md-8 text-center justify-content-center text-white">
                    <p class="titulo" id="quemSomos" ref="quemSomos">
                        Quem Somos
                    </p>
                    <p class="texto-descritivo">
                        Nossa empresa é composta por um grupo de profissionais com mais de 10 (dez) anos de experiência
                        comprovada no ramo de transportes, com Qualidade e Eficiência.
                        <br><br>
                        Nosso principal objetivo é a satisfação do cliente, consolidando assim parcerias duradouras.
                        <br><br>
                        Para atingirmos nosso objetivo, buscamos valorizar nossa equipe de funcionários, com treinamentos e
                        incentivos, pois, são eles que transformam a Damata em sinônimo de qualidade e tradição no ramo de
                        transportes.
                    </p>
                </div>
                <div :class="'col-md-3 col-lg-2 mb-5 align-item-end text-center align-self-end bg-white ' + fadeInDown">
                    <p class="texto-experiencia">
                        <span class="texto-idade">+{{this.experiencia}}</span>
                        <br>
                        Anos de experiência
                    </p>
                </div>
            </div>
        </figure>
        <FaixaObjetivo missao="Missão"
            textoDescricao="Realizar transportes de cargas, do ponto de origem ao ponto de destino, com segurança, eficiência, pontualidade e responsabilidade. Realizando a capacitação e atualização contínua dos nossos colaboradores."
            icon="fa-solid fa-bullseye" degradeIni="70%" degradeFim="100%" 
            :scrollData="this.scrollData"
            animacao="animate__animated animate__fadeInLeft animate__faster"
            :inicioAnimacao="1900+900"
            :fimAnimacao="3100+979"
            />
        <FaixaObjetivo missao="Visão"
            textoDescricao="Sermos reconhecidos como referência de excelência na prestação de serviços de transportes de cargas, visando atender as necessidades de nossos clientes e buscando crescimento contínuo na organização, bem como na renovação e atualização da nossa frota."
            icon="fa-regular fa-eye" degradeIni="51%" degradeFim="87%" 
            :scrollData="this.scrollData"
            animacao="animate__animated animate__fadeInLeft animate__fast"
            :inicioAnimacao="1900+900"
            :fimAnimacao="3100+979"/>
        <FaixaObjetivo missao="Valores"
            textoDescricao="Valorização de todos, através da urbanidade, confiança, dedicação, honestidade e reconhecimento, alcançando o comprometimento com a qualidade e o crescimento dos colaboradores."
            icon="fa-regular fa-gem" degradeIni="50%" degradeFim="80%" 
            :scrollData="this.scrollData"
            animacao="animate__animated animate__fadeInLeft animate__delay"
            :inicioAnimacao="1900+900"
            :fimAnimacao="3300+979"/>
    </div>
</template>
  
<script lang="js">
import FaixaObjetivo from "../Utils/FaixaObjetivo.vue"

export default {
    name: "QuemSomosHomePage",
    props: ['scrollData'],
    components: {
        FaixaObjetivo,

    },
    data() {
        return {
            animator: false,
            experiencia:0,
            quemSomos:this.$refs['quemSomos'],
        }
    },
    methods: {
        classAnimator(animacao,elemento) {
            let coordenadas;
            if(elemento){
                coordenadas = elemento?.getBoundingClientRect();
            }
            if (
                (500 >= (coordenadas?.bottom || 8000) &&
                (coordenadas?.bottom || -8000) >= -500
                ) && !this.animator) {
                this.animator = true;
                let intervalo;
                intervalo = setInterval(()=>{
                    if(this.experiencia < 10){
                        this.experiencia += 1;
                    }else{
                        clearInterval(intervalo);                        
                    }
                    
                },200)
                return animacao;
                // {scrollHeight,scrollTop,clientHeight};aa
            }
            else if (this.animator == true) {
                return animacao
            } else {
                return '';
            }
        },
        retornaElemento(){            
            return this.$refs['quemSomos'];
        }
    },
    computed: {
        fadeInDown() {
            if(this.scrollData){
                return this.classAnimator('animate__animated animate__fadeInDown',this.retornaElemento());
            }
            return'';

        }
    }
}
</script>
  
<style scoped>
.caixaImagem {
    position: relative;
    margin-top: 100px;
    height: 735px;
    background: linear-gradient(89.76deg, #00A538 58.39%, rgba(67, 198, 111, 0) 99.82%);
}

.texto-experiencia {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 29px;
    line-height: 2rem;
    align-items: center;
    text-align: center;

    color: #00A538;
}

.texto-idade {
    font-size: 94px;
    vertical-align: middle;
    text-align: center;
    width: 100%;
    line-height: 6rem;
}

.caminhaoCardGrande {
    /* width: auto;
    height: auto; */
    /* background-size: 100%; */
    right: 0;
    position: absolute;
    opacity: 0.5;
    display: block;
    width: 50%;
    z-index: -1;
    height: 735px;
}
</style>