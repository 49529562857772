<template>
    <div ref="faixa" :class="'row faixa d-flex mx-0 ' +tocaAnimacao " :style="faixaBackground">
        <div class="col w-sm-100 col-md-3 d-flex align-items-center justify-content-center h-100">
            <p class="faixa-titulo text-white">{{ this.missao }}</p>
        </div>
        <div class="row-sm mr-sm-3 w-sm-100 col-md-7 col-sm d-flex align-items-center justify-content-center h-100">
            <p class="faixa-descricao text-white">{{ this.textoDescricao }}</p>
            <font-awesome-icon :icon="this.icon" class="faixa-icon" />
        </div>
        <!-- <div class="col-3 d-flex align-items-center justify-content-center h-100">
                    
                </div> -->
    </div>
</template>

<script>
export default {
    name: "FaixaObjetivo",
    props: [
        "missao",
        "textoDescricao",
        "icon",
        "degradeIni",
        "degradeFim",
        "scrollData",
        "animacao",
        "inicioAnimacao",
        "fimAnimacao"
    ],
    data() {
        return {
            animator: false,
        }
    },
    methods: {
        classAnimator(animacao,elemento) {

            let coordenadas;
            if(elemento){
                coordenadas = elemento?.getBoundingClientRect();
            }
            if (
                (1500 >= (coordenadas?.bottom || 8000) &&
                (coordenadas?.bottom || -8000) >= -1500
                ) && !this.animator) {
                    
                this.animator = true;
                return animacao;
            }
            else if (this.animator == true) {
                return animacao
            } else {
                return '';
            }
        },
        pegaElemento(){
            return this.$refs['faixa'];
        }
    },
    computed: {        
        faixaBackground() {
            return {
                '--per-degrade-ini': this.degradeIni,
                '--per-degrade-fim': this.degradeFim
            };
        },        
        tocaAnimacao() {
            if(this.scrollData){
                return this.classAnimator(this.animacao, this.pegaElemento());
            }
            return'';
        }
    }
}
</script>

<style scoped>
.faixa {
    margin-top: 100px;
    height: 280px;

    background: linear-gradient(90deg, #00A538 var(--per-degrade-ini), rgba(0, 165, 56, 0) var(--per-degrade-fim));
}

.faixa-titulo {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 75px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
}

.faixa-descricao {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 35px;
    display: flex;
    align-items: center;
}

.faixa-icon {
    right: 50%;
    font-weight: 500;
    font-size: 17rem;
    opacity: 0.5;
    object-fit: cover;
    z-index: -3;
}

@media(max-width: 1250px) {
    .faixa {
        height: auto;
    }
    .faixa-titulo {
        text-align: center;
    }

    .faixa-icon {
        text-align: center;
        /* right: auto;
        left: auto; */
        z-index: 1;
        opacity: .3;
    }
}
@media(max-width: 700px) {
    .faixa-icon {
        position: absolute;
    }
    .faixa-icon {        
        font-size: 13rem;
        right: auto;
    }
    .faixa-descricao{
        text-align: justify;        
    }
    .faixa{
        background:  #00A538;
    }
    
    
}
</style>