<template>
  <div>
    <figure class="caixaImagem h-100">
      <img src="@/assets/caminhaoNossosServicos.jpg" class="caminhaoCardGrande h-100" alt="Caminhão">
      <div class="row-0 offset-sm-4 offset-md-4 offset-lg-4 offset-lx-4 text-center justify-content-center text-white ">
        <p class="titulo" id="nossosServicos">
          Nossos Serviços
        </p>
        <p class="texto-descritivo pb-5">
          <span class="texto-subtitulo"> TRANSPORTE DE CARGA RODOVIÁRIO:</span>
          A Damata faz transportes de cargas fracionadas ou exclusivas para todas as regiões de São Paulo, com veículos de
          médio e grande porte.
          <br><br>

          <span class="texto-subtitulo"> TRANSPORTE DE CARGA FRACIONADO CONVENCIONAL:</span>
          Com baixo custo e prazos convencionais é o mais utilizado pelo mercado.
          <br><br>

          <span class="texto-subtitulo"> TRANSPORTE DE CARGA FRACIONADO EXPRESSO:</span>
          Se o que mais importa é o tempo, conte com as soluções que a Damata oferece para fazer a sua carga chegar ao
          lugar certo e na hora certa.
          <br><br>

          <span class="texto-subtitulo"> TRANSPORTE DE CARGA EXCLUSIVO:</span>
          Indicado para cargas urgentes. Você pode contar com nosso sigilo, controle em real time, além de menor prazo
          para a conclusão até seu destino final.
        </p>        
      </div>      
      
    </figure>
    <FormularioEmail :corpo-class="fadeInDown" corpo-style="    
    background: rgba(0, 165, 56, 0.78);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);"/>
  </div>
</template>

<script lang="js">
import FormularioEmail from "../Utils/FormularioEmail.vue"
export default {
  name: "NossosServicosHomePage",
  components:{FormularioEmail},
  props:['scrollData'],
  data() {
        return {
            animator: false,
        }
    },
    methods: {
        classAnimator(animacao) {
            if (
                (this.scrollData.scrollTop + this.scrollData.clientHeight >= 0 &&
                    this.scrollData.scrollTop + this.scrollData.clientHeight <= 3000
                ) && !this.animator) {
                this.animator = true;
                return animacao;
                // {scrollHeight,scrollTop,clientHeight};aa
            }
            else if (this.animator == true) {
                return animacao
            } else {
                return '';
            }
        }
    },
    computed: {
        fadeInDown() {
            return this.classAnimator('animate__animated animate__fadeInDown');
        }
    }

}
</script>

<style scoped>
.caixaImagem {
  position: relative;
  margin-top: 100px;
  height: 735px;
  background: linear-gradient(270.13deg, #00A538 57.18%, rgba(67, 198, 111, 0) 99.88%);
}

.caminhaoCardGrande {
  /* width: auto;
  height: auto; */
  /* background-size: 100%; */
  position: absolute;
  opacity: 0.5;
  display: block;
  width: 50%;
  z-index: -1;
  height: 735px;
}
</style>