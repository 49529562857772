<template>
    <div :class="(corpoClass || ``) + ' row-md mx-0 col-lg-4 formularioEmail'" :style=corpoStyle>
        <p class="titulo-formulario text-white" :hidden=this.ativo.titulo>Solicite Orçamento</p>
        <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-form-input required class="mt-3" v-model="formEmail.nome" placeholder="Nome"></b-form-input>
            <b-form-input required class="mt-3" type="email" v-model="formEmail.email" placeholder="E-mail"></b-form-input>
            <b-form-input required class="mt-3" v-model="formEmail.telefone" placeholder="Telefone/WhatsApp"></b-form-input>
            <b-form-textarea required id="textarea" v-model="formEmail.mensagem" class="mt-3" placeholder="Mensagem" rows="3"
                max-rows="6"></b-form-textarea>
            <div :class="(botaoClass || '') + 'd-flex justify-content-center box-botao mb-5 '" :style="botaoStyle">
                <b-button type="submit" class="mt-3 col btn-enviar mb-5">ENVIAR</b-button>
            </div>
        </b-form>

    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: "FormularioEmail",
    props: ["tituloHidden", "corpoStyle", "corpoClass", "botaoClass", "botaoStyle"],
    data() {
        return {
            ativo: {
                titulo: this.tituloHidden,
            },
            show: true,
            formEmail: {
                nome: '',
                email: '',
                telefone: '',
                mensagem: ''
            },
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()
            let erro = '';
            if (!this.formEmail.nome) {
                erro+="Favor preencher o campo Nome!\n"
            }
            if (!this.formEmail.email) {
                erro+="Favor preencher o campo E-mail!\n"
            }
            if (!this.formEmail.telefone) {
                erro+="Favor preencher o campo Telefone!\n"
            }
            if (!this.formEmail.mensagem) {
                erro+="Favor preencher o campo Mensagem!\n"
            }
            if(erro){
                alert(erro);
                return;
            }
            console.log('aa');
                        axios({
            method: 'post',
            url: 'https://www.api.damatatransportes.com',
            data: {
                ...this.formEmail
            }
            }).then( (response)=> {
                console.log(response.data);
                alert('Email Enviado com sucesso!');
            }).catch(() =>{
                alert('Falha ao enviar o e-mail, tente mais tarde por favor');
            });
            
        },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.formEmail.nome = ''
                this.formEmail.email = ''
                this.formEmail.telefone = ''
                this.formEmail.mensagem = ''
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                    this.show = true
                })
            }
        }
    }
</script>

<style scoped>
.box-botao {
    border-bottom: #fff .3rem solid;
}

.btn-enviar {
    background: #FFFFFF;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 75px;
    align-items: center;
    text-align: center;

    color: rgba(0, 165, 56, 0.78);
}

/* .formularioEmail::before{
    content: '';
    height: auto;
} */
.titulo-formulario {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 3rem;
    line-height: 75px;
    margin: 1rem 0;
    align-items: center;
    text-align: center;
}

.formularioEmail {
    margin-left: 30px;
    padding: 1rem;
    z-index: 6;
    position: relative;
    margin-top: -50rem;
    margin-bottom: 17rem;
    left: 1rem;
}

@media(max-width: 993px) {
    .formularioEmail {
        position: relative;
        top: 0;
        left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}
</style>